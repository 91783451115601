<template>
  <div>
    <h3 class="li-us-glosary-title openSans-400 fz-19">{{ title }}</h3>
    <p class="li-us-glosary-description openSans-100 fz-17">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'utilitySetGlosaryItem',
  props: {
    title: String,
    description: String,
  }
}
</script>