<template>
  <div class="container-base section-container-padding bg-white">
    <my-project-header
      :title="project.title"
      :isNew="project.isDayAgo"
      :lastUpdate="project.created"
      :pipelinesUsed="project.pipelinesUsed"/>

    <b-row class="d-flex" v-if="currentConfigure == 1">
      <div class="li-utility-set-container">
        <back-button :title="$t('general.toProjects')" />
        <utility-set-detail
          :title="$t('li.title')"
          :version="'V 1.03'"
          :icon="'icon_utility_changetracking.svg'"
          :description="this.utilitySetDetail.usDescription" />
        <div class="container-base pl-0">
          <h3 class="li-us-glosary-title openSans-400 fz-19 text-left mb-4">{{$t('li.prerequisite')}} </h3>
          <b-table class="text-left" :items="Object.values(pipelinesNeeded)" :fields="pipelinesListFields" responsive="sm">
            <template v-slot:cell(pipeline)="row">
              <div class="d-flex">
                <img :src="`/assets/images/${row.item.icon}`" />
                <span class="li-pipelines-used openSans-400 fz-12 table-pipelinescol-item">{{row.item.name}}</span>
              </div>
            </template>
            <template v-slot:cell(status)="row">
              <div v-if="row.item.isConfigured && !row.item.pending">
                <img :src="'/assets/images/icon_li_table_configured.svg'" />
                <span>{{$t('li.configured')}}</span>
              </div>
              <div v-else>
                <div v-if="row.item.pending">
                  <span class="openSans-400 fz-16 library-card-date mx-2 processing-status ml-0">
                    <img class="va-middle mx-0" :src="'/assets/images/icon_processing_small.svg'"/>
                    <span class="va-middle">{{$t('li.processing')}}</span>
                  </span>
                </div>
                <div v-else>
                  <img :src="'/assets/images/icon_li_table_notconfigured.svg'" />
                  <span class="text-danger">{{$t('li.notConfigured')}}</span>
                </div>
              </div>
            </template>
            <template v-slot:cell(#)="row">
              <b-button v-if="row.item.canConfigure && !row.item.pending"
                        v-show="pipelinesNeeded['INGESTION'].isConfigured && !pipelinesNeeded['INGESTION'].pending"
                        @click="currentConfigure = 2"
                        class="mr-2 button-primary openSans-600 fz-12 ta-center button-pipelines-table">{{$t('li.configure')}}</b-button>
            </template>
          </b-table>
          <div class="table-continue-container">
            <div v-show="pipelinesNeeded['INGESTION'].isConfigured && pipelinesNeeded['INGESTION'].pending">
              <h3 class="li-us-glosary-title openSans-400 fz-15 processing-status">{{$t('li.pipelineProcessing')}}</h3>
              <p class="li-us-glosary-description openSans-100 fz-15 processing-status">
                {{$t('li.notify')}}</p>
            </div>
            <p class="warning-msg" v-show="!pipelinesNeeded['LANGUAGE_INTELIGENCE'].isConfigured && !pipelinesNeeded['LANGUAGE_INTELIGENCE'].pending">
              {{$t('li.needConfigure')}}
            </p>
            <b-button class="button-primary"
              :disabled="!allConfigure()"
              :to="'/' + this.$store.getters.getInstance + '/language-inteligence/document/' + this.$route.params.projectId"><span>{{$t('li.continue')}}</span></b-button>
          </div>
        </div>
      </div>
      <div class="li-glosary-container">
        <div><img class="screenshot-image" :src="'/assets/images/ss_li.png'"/></div>
        <utility-set-glosary-item v-for="glosaryItem in this.utilitySetGlosaryItem" v-bind:key="glosaryItem.title"
          :title="glosaryItem.title"
          :description="glosaryItem.description" />
      </div>
    </b-row>

    <b-row class="d-flex" v-if="currentConfigure === 2">
      <div class="li-utility-set-container">
        <back-button/>
        <br>
        <b-row class="li-header-initial-row text-left">
          <b-col cols="12">
            <h4 style="myproj-ontology-configure-label">{{$t('li.configure')}}</h4>
            <div class="d-flex">
              <h2 class="myproj-ontology-select-title">{{$t('li.selectOntologies')}}</h2>
              <b-button class="button-primary" @click="configureOntologies()" :disabled="isDisabled">{{$t('li.saveContinue')}}</b-button>
            </div>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col cols="3" >
            <div style="text-align:left; padding-bottom:11px; border-bottom: 1px solid #d3d3d3;width: 100%;">
              <span class="ontology-sections-title" >{{$t('li.ontologyGroup')}}</span>
            </div>
          </b-col>
          <b-col >
            <div style="text-align:left; padding-bottom:11px; border-bottom: 1px solid #d3d3d3; width: 100%;">
              <span class="ontology-sections-title">{{$t('li.ontology')}}</span>
            </div>
          </b-col>
        </b-row>
        <div v-for="ontology in ontologyGroups" v-bind:key="ontology.name" class="text-left">
          <b-row>
            <b-col cols="3">
              <div class="d-inline-flex">
                <b-form-checkbox v-model="ontology.status" :indeterminate="ontology.indeterminate" @change="ontology.toggleAll" stacked/>
                <span class="cursor-pointer" @click="activateOntologyGroup(ontology.name)">{{ontology.description}}</span>
              </div>
            </b-col>
            <b-col>
              <b-form-group>
                <b-form-checkbox-group v-model="selected" :options="ontology.ontologies" @change="ontology.markedPartial" stacked></b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- <div style="margin-top:20px; margin-bottom:40px;"> Selected: <strong>{{ selected }}</strong></div> -->
      </div>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import MyProjectHeader from '@/modules/common/components/MyProjectHeader'
import BackButton from '@/modules/common/components/BackButton.vue'
import UtilitySetDetail from '@/modules/languageInteligence/components/UtilitySetDetail.vue'
import UtilitySetGlosaryItem from '@/modules/languageInteligence/components/UtilitySetGlosaryItem.vue'
import LanguageIntelligenceService from "@/modules/languageInteligence/services/LanguageIntelligenceService";
import ProjectService from "@/modules/projects/services/ProjectService";

var currentProject = {}

export default {
  name: 'LanguageInteligence',
  components: { MyProjectHeader, BackButton, UtilitySetDetail, UtilitySetGlosaryItem },
  data() {
    return {
      utilitySetDetail: {
        usDescription: 'li.description',
      },
      utilitySetGlosaryItem: [{
        title: '',
        description: ''
      }],
      currentConfigure: 1,
      isDisable: true,
      pipelinesUsed: [],
      selected: [],
      ontologyGroups: [],
      ontologyGroupsStatus: {},
      project: {
        title: null,
        pipelineConfigs: null,
        isDayAgo: false,
        created: 'Less than 1 day ago'
      },
      polling: null,
      pipelinesListFields: [{
        key: 'pipeline',
        label: 'Pipeline needed:'
      }, {
        key: 'status',
        label: 'Status:'
      }, {
        key: '#', label: ''
      }],
      pipelinesNeeded: {
        'INGESTION': {
          name: 'Ingestion',
          canConfigure: false,
          isConfigured: true,
          pending: true,
          icon: 'icon_li_table_ingestion.svg'
        },
        'LANGUAGE_INTELIGENCE': {
          name: 'Ontologies',
          canConfigure: true,
          isConfigured: false,
          pending: false,
          icon: 'icon_li_table_ontology.svg'
        }
      }
    }
  },
  methods: {
    activateOntologyGroup(ontologyName) {
      Object.keys(this.ontologyGroupsStatus).forEach(key => {
        this.ontologyGroupsStatus[key] = ontologyName === key
      })
    },
    configureOntologies() {
      if(this.currentConfigure === 2 && this.selected.length > 0) {
        this.saveOntologiesProject()
      }
      this.currentConfigure = 1
    },
    saveOntologiesProject() {
      let ontologiesProject = {
        project_id: this.$route.params.projectId,
        ontologies: this.selected
      }
      this.pipelinesNeeded['LANGUAGE_INTELIGENCE'].pending = true

      this.polling = setInterval(() => {
        LanguageIntelligenceService.saveOntologies(ontologiesProject).then(response => {
          this.pipelinesNeeded['LANGUAGE_INTELIGENCE'].isConfigured = true
          if (this.pipelinesNeeded['LANGUAGE_INTELIGENCE'].pending) {
            this.pipelinesNeeded['LANGUAGE_INTELIGENCE'].pending = (response.data.status === 'PENDING')
          } else {
            clearInterval(this.polling)
            this.polling = null
          }
        })
      }, 4000)

    },
    searchOntologyGroups() {
      LanguageIntelligenceService.getOntologyGroups().then((response) => {
        let ontologyGroups = response.data.ontology_groups
        ontologyGroups.forEach(ontologyGroup => {
          let ontologies = []
          ontologyGroup.ontologies.forEach(ontology => {
            ontologies.push(Object.keys(ontology)[0])
          })
          ontologyGroup.ontologies = ontologies
        })

        this.ontologyGroups = ontologyGroups
        this.ontologyGroups.forEach(ontology => {
          this.ontologyGroupsStatus[ontology.name] = false
          ontology.status = false
          ontology.indeterminate = false
          ontology.toggleAll = checked => {
            if (checked) {
              ontology.ontologies.forEach(ontology => {
                this.selected.push(ontology)
              })
            } else {
              ontology.ontologies.forEach(ontology => {
                const index = this.selected.indexOf(ontology)
                if (index > -1) {
                  this.selected.splice(index, 1)
                }
              })
            }
            ontology.status = checked
          }
          ontology.markedPartial = () => {
            ontology.ontologies.forEach(currentOntology => {
              this.selected.includes(currentOntology)
            })
          }
        })
      }).catch((error) => {
        console.log(error)
      })
    },
    checkedPipelineConfigStatus() {
      ProjectService.get(this.$route.params.projectId, this.$route.params.bot).then(response => {
        let configs = {}
        let project = response.data
        project.pipeline_configs.forEach(config => {
          configs[config.type] = config
        })
        currentProject['pipelineConfigs'] = configs

        let ingestionConfig = currentProject.pipelineConfigs['INGESTION']
        this.pipelinesNeeded['INGESTION'].pending = ingestionConfig && ingestionConfig.status != "COMPLETED"

        var languageinteligence = currentProject.pipelineConfigs["LANGUAGE_INTELIGENCE"]
        if(languageinteligence) {
          this.pipelinesNeeded['LANGUAGE_INTELIGENCE'].isConfigured = true
          this.pipelinesNeeded['LANGUAGE_INTELIGENCE'].pending = languageinteligence.status != "COMPLETED"
        }
        if (ingestionConfig.status == "COMPLETED" ) {
          // when ingestion is completed, we stop polling for the project status
          clearInterval(this.polling)
        }
      })
    },
    initializeProject() {
      this.project.title = currentProject.title
      this.project.pipelineConfigs = currentProject.pipelineConfigs

      this.project.created = currentProject.created
      let created = new Date(this.project.created)
      let today = new Date(Date.now())
      this.project.isDayAgo = (today - created) <= 86400000

      let ingestionConfig = currentProject.pipelineConfigs['INGESTION']
      this.pipelinesNeeded['INGESTION'].pending = ingestionConfig && ingestionConfig.status != "COMPLETED"

      var languageinteligence = currentProject.pipelineConfigs["LANGUAGE_INTELIGENCE"]
      if(languageinteligence) {
        this.pipelinesNeeded['LANGUAGE_INTELIGENCE'].isConfigured = true
        this.pipelinesNeeded['LANGUAGE_INTELIGENCE'].pending = languageinteligence.status != "COMPLETED"
        this.selected = languageinteligence.pipeline_data.ontologies
      }

      this.pollData(this.checkedPipelineConfigStatus)

    },
    allConfigure() {
      let ingestion = this.pipelinesNeeded['INGESTION']
      let lang_inte = this.pipelinesNeeded['LANGUAGE_INTELIGENCE']
      return ingestion.isConfigured && !ingestion.pending && lang_inte.isConfigured && !lang_inte.pending
    },
    pollData(poller) {
      this.polling = setInterval(poller, 5000)
    }
  },
  computed: {
    isDisabled() {
      return !(this.selected.length > 0)
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  beforeRouteEnter(to, from, next) {
    ProjectService.get(to.params.projectId, to.params.bot).then(response => {
      let configs = {}
      let project = response.data
      project.pipeline_configs.forEach(config => {
        configs[config.type] = config
      })
      currentProject['title'] = project.project_name
      currentProject['pipelineConfigs'] = configs
      currentProject['created'] = project.created
      next()
    })
  },
  created() {
    this.searchOntologyGroups()
    this.initializeProject()
  }
}
</script>

<style lang="scss" scoped>

a.button-primary {
  display:flex;
  max-width:140px;
}

a.button-primary span {
  margin: auto !important;
}

table td span {
  vertical-align:middle;
  margin-left:14px;
}

.table-continue-container {
  text-align:left;
}

.button-pipelines-table {
  width: 94px;
  min-width: 94px;
  height: 34px;
  font-size: 12px;
}

.table-pipelinescol-item {
  font-size:15px;
  margin: auto 0px auto 16px;
}

th {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151515;
}
.table th {
  border-top:none;
}
.table td {
  border-bottom:1px solid #d3d3d3;
  vertical-align: middle;
}
.myproj-ontology-configure-label {
  font-family: OpenSans;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151515;
}
.myproj-ontology-select-title {
  font-family: OpenSans;
  font-size: 28px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151515;
  margin-right:30px;
}

.custom-control-label span, span.cursor-pointer {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--viridian);
}

.custom-control-label::before {
  border:1px solid var(--viridian);
  border-radius: 6px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--viridian);
  border-radius: 6px;
}

.custom-control-input:checked + label.custom-control-label span,
.custom-control.custom-checkbox .custom-control-input:checked + span.cursor-pointer {
  color:black;
}

.ontology-sections-title {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151515;
  margin-bottom:11px;
}

div.d-inline-flex:first {
    border-top: 1px solid #d3d3d3;
}

div.d-inline-flex {
  border-bottom: 1px solid #d3d3d3;
  padding: 20px 0px;
  min-height: 100%;
  min-width: 100%;
  align-items: center;
}

div.d-inline-flex {
  border-bottom: 1px solid #d3d3d3;
  min-height: 100%;
  min-width: 100%;
  align-items: center;
}

fieldset {
  border-bottom: 1px solid #d3d3d3;
  padding: 20px 0px;
  min-height: 100%;
  min-width: 100%;
  align-items: center;
}

.form-group {
  margin-bottom:0px;
}
</style>
