<template>
  <div class="container-base pl-0">
    <div class="li-utility-set-detail-container">
      <div><img class="li-utility-set-detail-image" :src="`/assets/images/${ icon }`" /></div>
      <span class="li-utility-set-detail-version openSans-100 fz-12">{{ version }}</span>
      <h2 class="li-utility-set-detail-title openSans-400 fz-19">{{ title }}</h2>
      <p class="li-utility-set-detail-description openSans-100 fz-17" v-html="$t(description)"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'utilitySetDetail',
  props: {
    icon: String,
    description: String,
    version: String,
    title: String
  }
}
</script>
